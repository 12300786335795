<template>
  <JetDialog
    v-model="dialog"
    title="Приказ"
  >
    <template v-slot:default>
      <div v-if="!loadingTariff">
        <div>
          Перевозчик:
          <span class="black--text">{{ tariff.shortName }}</span>
        </div>

        <!-- Дата -->
        <div>
          Дата:
          <span v-if="tariff.uploadDt" class="black--text">{{ tariff.uploadDt }}</span>
          <span v-else class="grey--text">Отсутствует</span>
        </div>

        <!-- Файл -->
        <div>
          Файл:
          <template v-if="tariff.file">
            <v-btn
              class="pa-0"
              text
              style="text-transform: initial"
              @click="openFile(tariff.file.ref)"
            >
              <u>{{ tariff.file.name || 'Файл' }}</u>
            </v-btn>
          </template>
          <span v-else class="grey--text">Отсутствует</span>
        </div>

      </div>
      <div v-else class="b-spinner align-center mt-6" align="center">
        <v-progress-circular indeterminate color="primary"/>
      </div>
    </template>

    <template v-slot:actions>
      <v-spacer/>
      <v-btn outlined color="red" @click="dialog = false">Закрыть</v-btn>
      <v-spacer/>
    </template>

  </JetDialog>
</template>

<script>
import BaseAction from "@/components/actions/BaseAction";
import JetDialog from "@/components/JetDialog";
import OtherService from "@/services/OtherService";

export default {
  name: "ShowNotifications",
  components: {
    JetDialog
  },
  mixins: [
    BaseAction,
  ],
  data: () => ({
    dialog: false,
    loadingTariff: false,
    tariff: {
      shortName: '',
      uploadDt: '',
      file: null,
    },
    notificationId: null,
  }),
  mounted() {
    if (!this.selected) {
      return;
    }
    this.notificationId = this.selected.vctariffingNotificationid;
    if (!this.notificationId) {
      jet.msg({
        text: 'Прикрепленный приказ отсутствует! Чтобы добавить приказ: Выберите запись, а затем выполните действие: "Прикрепить приказ"',
        color: 'warning'
      });
      return;
    }

    this.dialog = true;
    this.loadData();
  },
  methods: {
    async loadData() {
      this.loadingTariff = true;
      this.tariff = await OtherService.getTariffs(this.notificationId);
      this.loadingTariff = false;
    },
    // Открытие файла
    openFile(ref) {
      location.href = '/rpc/?d=file&uri=' + ref;
    },
  },
}
</script>
